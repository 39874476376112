import React from "react";
import LogoPart from "../assets/images/Icon/LogoPart";
import videoDesign from '../assets/images/video/video-design2.mp4';
import Mackbook from '../assets/images/mackbook.png';
import Card1 from '../assets/images/card-design1.png';
import Card2 from '../assets/images/card-design2.png';
import Button from './Button'
import WorkIcon from '@mui/icons-material/Work';

const datos = {
    subtitulo: "Hecho a tu medida",
    titulo: "Diseños 100% personalizados",
    texto: "Creamos sitios web únicos y completamente personalizados. trabajamos contigo para diseñar una página que refleje perfectamente tu visión y marca. Cada detalle está diseñado a tu gusto, garantizando una presencia en línea distintiva y profesional.",
    video: "https://www.youtube.com/embed/kP6_nlTdPgI?playlist=kP6_nlTdPgI&loop=1&autoplay=1&controls=0&modestbranding=1&showinfo=0&rel=0"
}

function DiseñoPersonalizado() {
    return ( 

        <section className="diseño">
            <div className="bgSection"> 
                <div className="container">
                    <div className="grid-2">
                        <div>
                            <div className="titleSection titleSectionStart">
                                <h6>
                                    <LogoPart />
                                    { datos.subtitulo }
                                </h6>
                                <h2>{datos.titulo}</h2>
                                <p>
                                {datos.texto}
                                </p>
                                <a href='#portafolio'>
                                <Button href="#portafolio">
                                    Ver Proyectos 
                                </Button>
                                </a>
                            </div>
                        </div>
                        <div className="drh">
                            <div className="contetVideoDiseño">
                                <img src={Mackbook} alt=''/>
                                <img className="card1" src={Card1} alt=''/>
                                <img className="card2" src={Card2} alt=''/>
                            <video  muted autoPlay loop   >
                                <source src={videoDesign} type="video/mp4" />
                            </video>

                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </section>

     );
}

export default DiseñoPersonalizado;