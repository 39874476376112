import React from "react";
import Button from "./Button";
import heroImg1 from '../assets/images/hero-1.jpg';
import heroImg2 from '../assets/images/hero-2.jpg';
import heroImg3 from '../assets/images/hero-3.jpg';
import heroImg4 from '../assets/images/hero-4.jpg';
import heroImg5 from '../assets/images/hero-5.jpg';

function Hero() {
  return (
    <div id="hero">
      <div className="contentHero">
        <div className="container">
          <div className="grid-2">
            <div className="izq">
              <h1>Transformamos tus ideas en soluciones digitales</h1>
              <p>
                Desarrollo web, tiendas online, sistemas de gestión, POS y apps
                móviles. Soluciones personalizadas para impulsar tu negocio.
              </p>

              <div className="contentBtn">
                <a href="#planes">
                  <Button className="btnBgBlack" >Ver Planes</Button>
                </a>
                <a href="https://wa.link/5xpnh0" target="_blank">
                  <Button className="btnRevers">Contáctanos</Button>
                </a>
              </div>
            </div>
            <div className="drh">

              <img className="heroImg1" src={heroImg1} alt=''/>
              <img className="heroImg2" src={heroImg2} alt=''/>
              <img className="heroImg3" src={heroImg3} alt=''/>
              <img className="heroImg4" src={heroImg4} alt=''/>
              <img className="heroImg5" src={heroImg5} alt=''/>
                
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
