import React from "react";
import Logo from "../assets/images/Icon/Logo";
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import Phone from "../assets/images/Icon/Phone";
import Line from "../assets/images/Icon/Line";
import Mail from "../assets/images/Icon/Mail";
import Instagram from "../assets/images/Icon/Instagram";
import Whatsapp from "../assets/images/Icon/Whatsapp";
import Facebook from "../assets/images/Icon/Facebook";
import { Link } from 'react-router-dom';


import ChevronRightIcon from '@mui/icons-material/ChevronRight';
function Footer() {
  return (
    <footer>
      <div className="contentFooter">

          <div className="container">
            <div className="grid-4">
              <div className="box">
                <Logo />
                <p>
                  Convertimos sueños en realidad, empezando por nuestro talentoso
                  equipo y extendiéndolos a nuestros clientes, con productos de alta
                  calidad respetuosos del entorno.
                </p>
              </div>
              <div className="box">
                <div className="titleBox">
                  <h4>Contacto</h4>
                  <Line/>
                </div>
                <ul>
                    <li>
                        <a href='tel:3214381060'>
                            <Phone/>
                            321 438 1060
                        </a>
                    </li>
                    <li>
                        <a href='https://wa.link/5xpnh0'>
                        <Whatsapp/>
                            (+57) 321 438 1060
                        </a>
                    </li>
                    <li>
                        <a href='mailto:contacto@sitcod.com'>
                            
                            <Mail/>
                            contacto@sitcod.com
                        </a>
                    </li>
                </ul>

              </div>
              <div className="box">
                <div className="titleBox">
                  <h4>Planes</h4>
                  <Line/>
                </div>
                <ul>
                    <li>
                        <a>
                            <ChevronRightIcon/>
                            Landing Páge
                        </a>
                    </li>
                    <li>
                        <a>
                            <ChevronRightIcon/>
                            Página Web
                        </a>
                    </li> 
                    <li>
                        <a>
                            <ChevronRightIcon/>
                            Tienda Online (Tipo WhatsApp)
                        </a>
                    </li>
                    <li>
                        <a>
                            <ChevronRightIcon/>
                            Tienda Online (Profesional)
                        </a>
                    </li>
                </ul>
              </div>


              <div className="box">
                <div className="titleBox">
                  <h4>Recursos</h4>
                  <Line/>
                </div>
                <ul>
                    <li>
                        <a href="http://localhost:3000/#planes">
                            <ChevronRightIcon/>
                            Planes
                        </a>
                    </li>
                    <li>
 
                        <Link to="/privacy-policy">
                        <ChevronRightIcon/>
                        Políticas de Privacidad</Link>
                    </li>
                    <li>
                        <a href='http://localhost:3000/#trabajo'>
                            <ChevronRightIcon/>
                            ¿Como trabajamos?
                        </a>
                    </li>
                    <li>
                        <a href='http://localhost:3000/#portafolio'>
                            <ChevronRightIcon/> 
                            Proyectos
                        </a>
                    </li>
                </ul>
              </div>



            </div>
            <div>
            </div>
          </div>
      </div>

                <div className="infoTop">
                    <p>Copyright ©2024 sitcod.com - All Rights Reserved</p>
                </div>


    </footer>





  );
}

export default Footer;
