import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={37}
    height={50}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M27.132 1.504c.63.495 1.215 1.22 1.778 1.794l4.381 4.548c.709.748 1.753 1.65 2.356 2.438l-.088.006c-.546.033-1.101.009-1.648.008l-4.843.001c-1.777.007-1.938-.048-1.934-1.832l-.002-6.963Z"
    />
    <path
      fill="#fff"
      d="M2.305.029C4.117.005 5.931.023 7.743.023h14.932c.894-.002 1.84-.069 2.726.035.043.656-.02 1.534-.021 2.213l-.003 5.059c0 1.836-.29 4.02 2.083 4.633.778.202 1.867.12 2.681.12l4.092-.002c.776-.001 1.564-.036 2.337.018.027 1.043.003 2.08.008 3.123l.01 30.786c.005 1.518.11 3.945-2.14 3.98-.754.024-1.516.005-2.271.005H3.792c-3.328.02-3.739-.522-3.74-3.898L.053 5.269c0-2.084-.601-4.821 2.252-5.24Zm19.298 23.243c-1.186.49-7.577 3.837-8.072 4.35v5.2c0 .995-.048 1.984.02 2.979.414.572 1.192.85 1.8 1.16l2.186 1.116c.806.414 3.424 1.98 4.034 1.947.698-.206 1.364-.587 2.005-.925l2.464-1.31c.826-.432 3.064-1.428 3.569-1.968.048-.492.023-.995.02-1.488l-.003-4.981c0-.533.023-1.079-.013-1.61-.601-.85-1.886-1.286-2.77-1.759-.768-.41-4.77-2.698-5.24-2.71Z"
    />
    <path
      fill="#fff"
      d="M15.244 29.906c.4.162.903.507 1.307.723 1.25.67 2.793 1.546 4.085 2.11.095 1.557.052 3.328.045 4.918-.575-.155-1.134-.583-1.665-.858-1.218-.631-2.497-1.296-3.75-1.846.03-1.667.082-3.383-.022-5.047ZM27.83 29.945l.035.004c.139.164.035 4.353.034 4.962-1.818.948-3.65 1.896-5.49 2.798l-.044-.022c-.04-.098-.003-4.385-.011-4.916.454-.19.895-.442 1.333-.668l2.507-1.307c.542-.287 1.076-.6 1.636-.85ZM21.578 25.237c.224.04.876.448 1.111.574l3.158 1.677c.483.257.964.58 1.468.786-1.503.703-2.977 1.505-4.447 2.275-.307.16-1.023.628-1.286.698-1.719-.792-4.178-2.096-5.843-3.066 1.98-.913 3.861-2.031 5.839-2.944Z"
    />
    <path
      fill="#BAFD00"
      d="M18.41 7.603c.753.052 1.53.046 2.282-.016-.176 3.26-.128 7.52.013 10.778-.607.285-1.182.65-1.772.968l-7.501 4.024c-.612.334-1.284.631-1.844 1.045-.073 3.373.023 6.753-.018 10.127-.817.016-1.637.008-2.454.01-.78 0-1.56.005-2.338-.018l-.01-.14c-.038-.686-.023-1.376-.023-2.063V13.806c0-2.345-.327-5.308 2.745-6.065.848-.21 2.302-.114 3.202-.114h4.832c.96 0 1.928.029 2.886-.024Z"
    />
  </svg>
)
export default SvgComponent
