import React, { useState } from "react";  
import axios from "axios";
import IconLogo from "../assets/images/Icon/IconLogo";
import { Link } from "react-router-dom";

function Ingreso() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [errors, setErrors] = useState({});

    const handleSubmit = async (event) => {
        event.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length === 0) {
            try {
                const response = await axios.post('http://127.0.0.1:8000/api/ingreso', {
                    email,
                    password,
                    rememberMe
                });
                console.log(response.data);
                 
            } catch (error) {
                console.error('Error al enviar el formulario', error);
            }
        } else {
            setErrors(validationErrors);
        }
    };

    const validateForm = () => {
        const errors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!email) {
            errors.email = 'El correo electrónico es requerido';
        } else if (!emailRegex.test(email)) {
            errors.email = 'El correo electrónico no es válido';
        }

        if (!password) {
            errors.password = 'La contraseña es requerida';
        } else if (password.length < 6) {
            errors.password = 'La contraseña debe tener al menos 6 caracteres';
        }

        return errors;
    };

    return (
        <section id="ingreso">
            <div className="container">
                <form onSubmit={handleSubmit}>
                    <IconLogo/>
                    <h1>Ingreso</h1>
                    <p>Por favor ingresa tus datos para continuar</p>
                    <div className="form-group">
                        <label htmlFor="email">Correo Electrónico</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Ingresa tu correo"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className={errors.email ? 'error' : ''}
                        />
                        {errors.email && <span className="error-message">{errors.email}</span>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Contraseña</label>
                        <div className="password-wrapper">
                            <input
                                placeholder="ingresa tu contraseña"
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className={errors.password ? 'error' : ''}
                            />
                            <div
                                type="button"
                                className="toggle-password"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? '🙈' : '👁️'}
                            </div>
                        </div>
                        {errors.password && <span className="error-message">{errors.password}</span>}
                    </div>
                    <div className="form-group rememberMe">
                        <label>
                            <input
                                type="checkbox"
                                name="rememberMe"
                                checked={rememberMe}
                                onChange={(e) => setRememberMe(e.target.checked)}
                            />
                            Recordar contraseña
                        </label>
                    </div>
                    <button type="submit">Ingresar</button>
                    <Link className="olvideContra" to="olvide-mi-contraseña">Olvide mi contraseña</Link>
                </form>
            </div>
        </section>
    );
}

export default Ingreso;
