import * as React from "react"
const Close = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={21}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M3.93 18.07A10 10 0 1 1 17.824 3.684 10 10 0 0 1 3.93 18.07ZM12.4 11l2.83-2.83-1.41-1.41L11 9.59 8.17 6.76 6.76 8.17 9.59 11l-2.83 2.83 1.41 1.41L11 12.41l2.83 2.83 1.41-1.41L12.41 11h-.01Z"
    />
  </svg>
)
export default Close
