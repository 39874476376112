import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={57}
    height={50}
    fill="none"
    {...props}
  >
    <path
      fill="#0A0A0D"
      d="M3.739.023C4.944-.02 6.159.011 7.365.011L50.136.009c2.615 0 5.689.236 5.978 3.725.063.756.009 1.559.008 2.32l-.002 37.958c.002.79.054 1.613-.019 2.398-.182 1.963-1.808 3.415-3.75 3.553-.628.09-1.298-.01-1.932-.013L6.31 49.948c-1.648-.002-3.33.297-4.734-.79C-.16 47.815 0 46.253.006 44.328L.009 6.327C.003 3.556-.038.434 3.739.023Z"
    />
    <path
      fill="#BAFD00"
      d="M28.113 16.475c.303.063 9.615 5.097 10.166 5.436-3.305 1.445-7.088 3.317-10.17 5.144-.583-.344-1.226-.619-1.827-.93l-4.407-2.24c-1.328-.661-2.64-1.463-4.037-1.964 3.207-1.958 6.937-3.509 10.275-5.446ZM17.222 23.803c.537.275 1.033.64 1.571.92a303.922 303.922 0 0 0 8.137 4.086c-.022.792.03 1.604.03 2.4l.001 6.385c0 .916.04 1.85-.01 2.764l-.059.024c-2.817-1.218-5.47-2.806-8.215-4.18-.48-.24-.974-.453-1.44-.717-.062-1.4-.02-2.818-.02-4.22l.005-7.462ZM38.752 23.896l.04.03c.085.354.02 2.105.02 2.57v5.672c0 1.106-.044 2.234.037 3.338-3.074 1.665-6.542 3.363-9.699 4.906l-.036-.01c-.072-.143-.016-10.511-.03-11.558 3.07-1.918 6.532-3.143 9.668-4.948Z"
    />
    <path
      fill="#fff"
      d="M47.783 2.178c1.124.05 3.67-.135 4.587.082 1.794.424 1.553 2.32 1.552 3.724l.01 3.122c-3.91-.068-7.83-.011-11.742-.011H12.638c-1.421 0-2.847.023-4.267-.015-2.048.062-4.104.004-6.153.022L2.217 5.64c-.01-2.767-.07-3.437 3.24-3.44l38.258.002c1.353 0 2.716.036 4.068-.024Z"
    />
    <path
      fill="#0A0A0D"
      d="M47.746 4.51c.697-.015 2.002-.132 2.61.204 1.039.576.547 1.757-.49 1.964-.707.077-2.235.225-2.796-.209-.89-.688-.301-1.844.676-1.958ZM40.47 4.505c.586-.031 2.248-.114 2.722.15 1 .555.538 1.843-.438 2.065-.638.04-2.118.145-2.659-.128-1.018-.515-.613-1.854.375-2.087Z"
    />
  </svg>
)
export default SvgComponent
