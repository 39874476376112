import * as React from "react"
const LogoPart = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={26}
    fill="none"
    {...props}
  >
    <path
      fill="#0A0A0D"
      d="M18.578 16.06a3.33 3.33 0 0 0-3.893-5.407L3.586 18.646a3.33 3.33 0 1 0 3.893 5.406l11.1-7.993Z"
    />
    <path
      fill="#BAFD00"
      d="M7.48 2.816A3.33 3.33 0 1 0 3.587 8.22l11.099 7.994a3.33 3.33 0 0 0 3.893-5.406l-11.1-7.993Z"
    />
  </svg>
)
export default LogoPart
