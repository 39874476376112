import * as React from "react"
const Whatsapp = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={13}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M6.5 0A6.5 6.5 0 0 0 .935 9.86l-.58 1.97a.657.657 0 0 0 .815.815l1.97-.58A6.5 6.5 0 1 0 6.5 0ZM5.03 7.971C6.345 9.285 7.6 9.459 8.043 9.475c.674.025 1.33-.49 1.586-1.087a.452.452 0 0 0-.057-.457c-.356-.455-.838-.782-1.309-1.107a.462.462 0 0 0-.632.102l-.39.595a.149.149 0 0 1-.198.05 4.742 4.742 0 0 1-.927-.686 4.23 4.23 0 0 1-.655-.893.148.148 0 0 1 .044-.189l.6-.446a.463.463 0 0 0 .078-.61c-.29-.427-.63-.97-1.122-1.329a.452.452 0 0 0-.445-.048c-.598.256-1.115.912-1.09 1.588.016.443.19 1.698 1.504 3.013Z"
      clipRule="evenodd"
    />
  </svg>
)
export default Whatsapp
