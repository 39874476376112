import * as React from "react"
const Line = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={61} height={2} fill="none">
    <path
      stroke="#0A0A0D"
      strokeLinecap="round"
      strokeWidth={2}
      d="M1 1h36.78M42.377 1h6.896M53.104 1H60"
    />
  </svg>
)
export default Line
