import React from "react";  
import LogoPart from "../assets/images/Icon/LogoPart";
import Button from "./Button";
import imgIncluido from "../assets/images/bg-incluido.jpg";
function TodoIncluido() {

    const datos = {
        subtitulo: "Todo Incluido por un Solo Precio",
        titulo: "Servicios Completos para Tu página o tienda online.",
        texto: "No te preocupes por costos adicionales ni servicios separados: con nosotros, tienes todo lo esencial para una presencia en línea robusta y profesional, todo en un solo paquete y a un precio accesible.",
    };

    return ( 

        <section id="TodoIncluido">
            <div className="container">
                <div className="grid-2">
                    <div className="izq">
                    <div className="titleSection titleSectionStart">
                                <h6>
                                    <LogoPart />
                                    { datos.subtitulo }
                                </h6>
                                <h2>{datos.titulo}</h2>
                                <p>
                                {datos.texto}
                                </p>
                                <a href='#planes'>
                                <Button href="#planes">
                                    Ver Planes 
                                </Button>
                                </a>
                            </div>
                    </div>
                    <div className="drh">
                        <img src={imgIncluido} alt=''/>
                    </div>
                </div>
            </div>
        </section>

     );
}

export default TodoIncluido;