import * as React from "react"
const IconLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={71}
    height={43}
    fill="none"
    {...props}
  >
    <path
      fill="#BAFD00"
      d="M4.504 17.322a5.55 5.55 0 1 0 6.486 9.006L29.48 13.01a5.55 5.55 0 0 0-6.486-9.006L4.504 17.322Z"
    />
    <path
      fill="#000"
      d="M22.994 39.388a5.55 5.55 0 0 0 6.486-9.006L10.99 17.064a5.55 5.55 0 0 0-6.487 9.006l18.491 13.317Z"
    />
    <path
      fill="#BAFD00"
      d="M65.518 26.076a5.55 5.55 0 0 0-6.486-9.006L40.54 30.386a5.55 5.55 0 1 0 6.486 9.007l18.49-13.317Z"
    />
    <path
      fill="#000"
      d="m47.022 4.005 12.77 9.193-9.304 6.977-9.95-7.164a5.55 5.55 0 0 1 6.484-9.006Z"
    />
  </svg>
)
export default IconLogo
