import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Planes from './components/planes/Planes';
import Portafolio from './components/portafolio/Portafolio';
import DiseñoPersonalizado from './components/DiseñoPersonalizado';
import Administrable from './components/Administrable';
import Trabajo from './components/trabajo/Trabajo';
import CallToAction from './components/CallToAtion';
import Contacto from './components/Contacto';
import Nosotros from './components/Nosotros';
import WhatsappFlotante from './components/WhatsappFlotante';
import PrivacyPolicy from './Views/PrivacyPolicy';
import Ingreso from './Views/Ingreso';
import TodoIncluido from './components/TodoIncluido';
import './assets/styles/App.scss';

function HomePage() {
  return (
    <>
      <Header/>
      <Hero/>
      <Nosotros/>
      <DiseñoPersonalizado/>
      <Administrable/>
      <TodoIncluido/>
      <Portafolio/>
      <Planes/>
      <CallToAction/>
      <Trabajo/>
      <Contacto/>
      <Footer/>
      <WhatsappFlotante/>
    </>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/ingreso" element={<Ingreso />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
