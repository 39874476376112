import React from "react";  
import LogoWhatsapp from "../assets/images/Whatsapp-logo.png"
function WhatsappFlotante() {
    return ( 
        <a className="WhatsappFlotante" href="https://wa.link/5xpnh0" target="_blank">
            <img src={LogoWhatsapp} alt=''/>
        </a>
     );
}

export default WhatsappFlotante;