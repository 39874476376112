import React from "react";
import LogoPart from "../../assets/images/Icon/LogoPart";
import IconWork1 from "../../assets/images/Icon/IconWork1";
import IconWork2 from "../../assets/images/Icon/IconWork2";
import IconWork3 from "../../assets/images/Icon/IconWork3";
import IconWork4 from "../../assets/images/Icon/IconWork4";

const datas = [
  {
    numero: "01",
    icono: <IconWork1 />,
    nombre: "Documentación",
    descripcion:
      "Recopilamos toda la información necesaria, como datos de contacto, información de la empresa, fotos, galería de imágenes, portafolio y detalles sobre tus servicios o productos.",
  },
  {
    numero: "02",
    icono: <IconWork2 />,
    nombre: "Diseño",
    descripcion:
      "Creamos el diseño desde cero, basándonos en la información proporcionada por ti. Utilizamos los colores de tu logo o manual de marca, y toda la información de tu empresa.",
  },
  {
    numero: "03",
    icono: <IconWork3 />,
    nombre: "Desarrollo",
    descripcion:
      "Transformamos los diseños en un producto funcional utilizando tecnologías modernas y prácticas de programación, asegurando un código limpio, eficiente y seguro.",
  },
  {
    numero: "04",
    icono: <IconWork4 />,
    nombre: "Entrega",
    descripcion:
      "Realizamos una reunión final para explicar el proyecto y ofrecer una capacitación de una hora. Entregamos todos los accesos después del pago del 50% restante.",
  },
];

function Trabajo() {
  return (
    <section id="trabajo" className="trabajo">
      <div className="container">
        <div className="titleSection">
          <h6>
            <LogoPart />
            Nuestro Proceso
          </h6>
          <h2>Desde la Idea hasta la Implementación: Nuestro Enfoque Integral

</h2>
          <p>
          En nuestra empresa, seguimos un enfoque integral que garantiza la calidad y el éxito de cada proyecto. Acompañamos a nuestros clientes en cada etapa del proceso, desde la recopilación de información inicial hasta la entrega final del producto. Nuestro objetivo es transformar tus ideas en soluciones digitales efectivas y personalizadas que impulsen tu negocio o empresa.
          </p>
        </div>
        <div className="grid-4">
          {datas.map((data, index) => (
            <div
              key={index}
              className={`box ${data.numero === "01" || data.numero === "03" ? "boxBlack" : ""}`}
            >
              <span>{data.numero}</span>
              {data.icono}
              <h3>{data.nombre}</h3>
              <p>{data.descripcion}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Trabajo;
