import * as React from "react"
const Angle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11}
    height={7}
    fill="none"
    {...props}
  >
    <path
      d="m.3 1.7 4.2 4.2c.4.4 1 .4 1.4 0l4.2-4.2c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L5.2 3.8 1.7.3C1.5.1 1.3 0 1 0 .7 0 .5.1.3.3c-.4.4-.4 1 0 1.4Z"
    />
  </svg>
)
export default Angle
