import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import LogoPart from "../assets/images/Icon/LogoPart";
import Button from "./Button";
import imgContacto from "../assets/images/imgContacto.png";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link } from 'react-router-dom';

// Definir tus credenciales aquí
const YOUR_SERVICE_ID = 'service_a8hxgb9';
const YOUR_TEMPLATE_ID = 'template_uhjwuz7';  // Asegúrate de reemplazar esto con tu Template ID
const YOUR_USER_ID = 'lRdZCImOVG6cjsO1e';  // Public Key

function Contacto () {
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');  // Estado para mensajes de validación
    const [acceptedPrivacy, setAcceptedPrivacy] = useState(false);  // Estado para el checkbox de privacidad

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!acceptedPrivacy) {
            setMessage('Debes aceptar las políticas de privacidad para continuar.');
            return;
        }

        const templateParams = {
            from_name: name,
            phone_number: phoneNumber,
            to_email: 'jiiglox@gmail.com',
        };

        emailjs.send(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, templateParams, YOUR_USER_ID)
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setMessage('Datos enviados con éxito. Nos pondremos en contacto contigo pronto.');
            }, (error) => {
                console.log('FAILED...', error);
                setMessage('Ocurrió un error al enviar los datos. Por favor, inténtalo de nuevo.');
            });

        setName('');
        setPhoneNumber('');
        setAcceptedPrivacy(false);
    };

    return (
        <section className="contacto">
            <div className="container">
                <div className="grid-2">
                    <div className="izq">
                        <div className="titleSection titleSectionStart">
                            <h6>
                                <LogoPart />
                                ¡Hablemos de tu Próximo Proyecto!
                            </h6>
                            <h2>Contacta con Nosotros para Más Información</h2>
                            <p>
                                ¿Estás interesado en saber más sobre nuestros planes de diseño y desarrollo? Ofrecemos una amplia gama de servicios, desde páginas web y landing pages hasta tiendas online, aplicaciones móviles y desarrollos a medida. Nuestro equipo está aquí para ayudarte a dar el siguiente paso en tu presencia digital. ¡Contáctanos hoy mismo para una consulta gratuita y descubre cómo podemos convertir tus ideas en realidad!
                            </p>
                            <a href='https://wa.link/5xpnh0'  target="_blank">
                                <Button>
                                    Más información <WhatsAppIcon/>
                                </Button>
                            </a>
                        </div>
                    </div>
                    <div className="drh">
                        <form onSubmit={handleSubmit}>
                            <h6>¿Deseas que te contactemos de inmediato?</h6>
                            <h3>Nosotros te llamamos</h3>
                            <div className='contentInput' >
                                <label htmlFor="name">Nombre:</label>
                                <input
                                    type="text"
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder='Ingresa tu nombre:'
                                    required
                                />
                            </div>
                            <div className='contentInput'>
                                <label htmlFor="phoneNumber">Número de Teléfono:</label>
                                <input
                                    type="tel"
                                    id="phoneNumber"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    placeholder='Ingresa un número de contacto:'
                                    required
                                />
                            </div>
                            <div className='contentPoliticas'>
                                <input
                                    type="checkbox"
                                    id="privacyPolicy"
                                    checked={acceptedPrivacy}
                                    onChange={(e) => setAcceptedPrivacy(e.target.checked)}
                                    required
                                />
                                <label htmlFor="privacyPolicy">
                                    Acepto las <Link to="/privacy-policy"  target="_blank">Políticas de Privacidad</Link>
                                </label>
                            </div>
                            <button type="submit">Enviar</button>
                        {message && <p className='mensaje'>{message}</p>} 
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contacto;
