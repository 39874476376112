import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={75}
    height={50}
    fill="none"
    {...props}
  >
    <path
      fill="#0A0A0D"
      d="M6.807.034C7.37-.036 7.971.024 8.54.026L52.243.03c4.986 0 9.978.056 14.964-.011 1.491-.02 1.49.898 1.497 2.14L68.617 40.7c1.527.016 3.818-.151 5.26.016.463.383.678.746.688 1.37.035 2.088.343 5.097-1.16 6.618-1.585 1.605-3.48 1.259-5.51 1.272l-62.108-.003c-.727.005-1.496.065-2.218-.016C-.14 49.54.01 46.02 0 43.33c-.01-3.154.25-2.62 3.25-2.624.907 0 1.814-.05 2.72-.024.029-1.09.002-2.187.002-3.278l-.003-32.88c0-1.258-.544-4.071.838-4.491Z"
    />
    <path
      fill="#fff"
      d="M43.1 42.835c2.665.082 5.348.024 8.015.024h14.4c2.291 0 4.591-.052 6.882.024.022.647.01 1.297.011 1.945.002 1.948-.23 2.925-2.408 2.962-4.668.078-9.35.006-14.019.006H7.296c-1.03 0-2.075.034-3.102-.039-2.623-.185-2.012-3.089-1.98-4.917.961-.013 1.928.018 2.89.018l26.378.008c-.14 2.799.042 2.623 2.47 2.62l6.617-.001c.705.001 1.502.11 2.183-.107.65-.673.41-1.7.348-2.543Z"
    />
    <path
      fill="#0A0A0D"
      d="M11.605 2.21c4.89.096 9.798.016 14.69.016l40.228.01c-.15 1.916.103 3.856-.043 5.731l-3.62.004-52.748-.007c-.658-.002-1.314-.047-1.971-.013.062-1.9.022-3.81.02-5.711 1.147-.033 2.296-.007 3.444-.03Z"
    />
    <path
      fill="#fff"
      d="M17.393 3.803c1.677.057 3.361.007 5.04.03v2.285c-1.622.1-3.377.09-4.994-.03a54.788 54.788 0 0 1-.046-2.285ZM10.148 3.839c1.713-.056 3.436-.022 5.15-.005a62.776 62.776 0 0 0-.036 2.238c-1.692.117-3.4.068-5.097.064-.017-.765-.014-1.532-.017-2.297ZM24.588 3.782c1.679.107 3.484.115 5.16-.009-.007.788.03 1.581.047 2.369-1.73 0-3.498.078-5.224-.023-.004-.78.003-1.558.017-2.337ZM18.607 10.12c1.61.107 3.26.038 4.874.038l30.982-.002c4.001 0 8.015-.068 12.015.02v26.626c0 1.311.039 2.636-.033 3.945-1.56-.11-3.16-.042-4.724-.042l-8.36-.002h-7.108c-.871 0-4.425-.22-5.072.286-.405.316-.209 1.85-.203 2.373-2.44-.056-4.878-.02-7.317-.047.044-1.341.278-2.42-1.354-2.569-.985-.09-2.004-.041-2.993-.042l-21.162.006c.04-2.272.01-4.547.01-6.82l.002-19.448c0-1.416.06-2.857-.051-4.268 3.439-.092 7.11.166 10.494-.055Z"
    />
    <path
      fill="#BAFD00"
      d="M42.93 20.564c.957-.046 1.364.53 1.999 1.146.663.645 3.546 3.227 3.5 4.133-.026.54-3.995 4.24-4.64 4.858-.817.665-2.253-.356-1.613-1.306.694-1.028 2.612-2.622 3.542-3.659-.639-.85-1.447-1.63-2.207-2.372-.598-.584-2.384-2.315-.581-2.8ZM31.227 20.51c.4-.021.709-.036 1.037.235 1.142.94-.558 2.105-1.18 2.685-.796.745-1.584 1.496-2.312 2.309.815.678 3.108 2.954 3.653 3.73.508.724-.312 1.454-1.014 1.521-.988-.207-2.973-2.475-3.747-3.28-.801-.834-1.469-1.205-1.445-2.42.38-.745 4.013-4.472 5.008-4.78ZM40.05 18.557c2.516-.017.41 3.056-.053 4.047l-2.744 5.855c-.487 1.024-1.397 3.86-2.343 4.1-2.496-.23-.627-2.875-.09-4.074.784-1.747 1.588-3.48 2.407-5.21L38.44 20.7c.373-.798.649-1.908 1.61-2.143Z"
    />
  </svg>
)
export default SvgComponent
