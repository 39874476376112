import * as React from "react"
const Info = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}
  >
    <path fill="#0A0A0D" d="M3.667 1.667h6.667v8H3.667z" />
    <path
      fill="#BAFD00"
      d="M7 3.667a.646.646 0 0 0 .475-.192A.642.642 0 0 0 7.667 3a.648.648 0 0 0-.192-.475A.643.643 0 0 0 7 2.333a.643.643 0 0 0-.475.192.648.648 0 0 0-.192.475c0 .188.064.347.192.475A.64.64 0 0 0 7 3.667ZM6.333 9h1.334V5H6.333v4Zm-6 4.667v-12c0-.367.131-.68.392-.942.262-.26.575-.391.942-.392h10.666c.367 0 .681.13.942.392.262.262.392.575.392.942v8c0 .366-.13.68-.392.942a1.28 1.28 0 0 1-.942.39H3L.333 13.668Z"
    />
  </svg>
)
export default Info
