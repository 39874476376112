// src/components/Accordion.jsx
import React, { useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import Angle from "../assets/images/Icon/Angle";

const AccordionItem = ({ title, content, isOpen, onClick }) => {
  const animation = useSpring({
    opacity: isOpen ? 1 : 0,
    height: isOpen ? "auto" : 0,
    transform: isOpen ? "translateY(0)" : "translateY(-20px)",
    config: { tension: 200, friction: 20 },
  });

  return (
    <div className="accordion-item">
      <div className="accordion-header" onClick={onClick}>
        <h4>{title}</h4>
        <div className={`icon-container ${isOpen ? "open" : ""}`}>
          <Angle />
        </div>
      </div>
      <animated.div
        style={{
          ...animation,
          display: isOpen ? 'flex' : 'none',
        }}
        className="accordion-content"
      >
        {content.map((line, index) => (
          <p key={index} className="textList">{line}</p>
        ))}
      </animated.div>
    </div>
  );
};

const Accordion = ({ items }) => {
  const [openIndex, setOpenIndex] = useState(0); // Cambia null a 0 para dejar abierto el primer elemento por defecto

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="accordion">
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          content={item.content}
          isOpen={openIndex === index}
          onClick={() => handleClick(index)}
        />
      ))}
    </div>
  );
};

export default Accordion;
