import React from "react";
import LogoPart from "../assets/images/Icon/LogoPart";
function Nosotros() {
    return (    

        <section id="nosotros" style={{ margin: 20, }}>
            <div className="container">
            <div className="titleSection " style={{ marginBottom: 0, }} >
                            <h6>
                            Tu Socio en Innovación Digital
                            </h6>
                            <h2>Bienvenidos a Sitcod</h2>
                            <p>
                            En Sitcod, somos una agencia de desarrollo especializada en crear soluciones digitales innovadoras y a medida. Con un enfoque en diseño web, tiendas online, sistemas de gestión, sistemas POS y aplicaciones móviles, nuestro objetivo es ayudar a nuestros clientes a transformar sus ideas en realidades digitales impactantes.
                            </p>
                        </div>
            </div>
        </section>
     );
}

export default Nosotros;