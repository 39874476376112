import React from "react";
import LogoPart from "../assets/images/Icon/LogoPart";
import Button from "./Button";
function CallToAction() {
    return ( 
        <section className="CallToAction">
            <div className="contentCallToAction">
                <div className="container">
                <div className="titleSection titleSectionWhite">
                    <h6>
                        
                        Servicios personalizados
                    </h6>
                    <h2>¿Deseas un desarrollo <br></br> personalizado a la medida?</h2>
                    <p>
                    Estamos aquí para transformar tus ideas en realidad. Desde la planificación y la implementación hasta el despliegue final, te acompañamos en cada paso del proceso. Permítenos convertir tus conceptos en soluciones concretas y efectivas.
                    </p>
                    </div>
                    <a  target="_blank" href="https://wa.link/us5gs1">

                    <Button className="btnBgBlack">
                        Cotizar gratis ahora!
                    </Button>
                    </a>

                </div>
            </div>
        </section>
     );
}

export default CallToAction; 