import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={35}
    height={55}
    fill="none"
    {...props}
  >
    <path
      fill="#0A0A0D"
      d="M26.647 0c.592.084 1.183.579 1.691.868l4.2 2.371c.431.245.912.474 1.287.801.349.959.108 7.65.108 9.152l.004 26.468c0 .455.036.94-.044 1.39l-.012.065c-.103.585-2.874 1.75-3.584 2.122L10.093 53.574c-.843.428-1.93 1.111-2.79 1.41-.446.144-1.638-.766-2.036-.999-.68-.398-4.936-2.786-5.146-3.326-.16-.412-.076-5.6-.076-6.395v-28.44c0-.496-.149-1.68.095-2.114.297-.527 7.891-4.229 8.748-4.667l15.506-7.89C25.06.805 25.94.176 26.647 0Z"
    />
    <path
      fill="#BAFD00"
      d="M16.153 12.8c.182.197.072 2.165.072 2.528l.007 4.607c-2.324 1.059-4.603 2.209-6.923 3.276l-.05-.019c.066-.395 1.998-3.129 2.39-3.711l3.155-4.685c.452-.663.87-1.35 1.349-1.996Z"
    />
    <path
      fill="#fff"
      d="M26.649 1.814c1.44.69 2.964 1.758 4.41 2.545-1.392.632-2.818 1.187-4.223 1.787L17.92 9.998c-.77.337-1.874.462-2.45 1.08-.444.476-.822 1.061-1.194 1.597l-1.757 2.56c-1.866 2.72-3.741 5.427-5.56 8.177-1.14 1.725-.85 1.068-.827 3.301l.057 13.78c.009 1.39-.011 2.783.036 4.172-1.082.297-3.466 1.065-4.475 1.22-.02-.68.002-1.364.002-2.043V19.386c0-1.478-.08-3.004.083-4.472.745-.008 1.477.578 2.112.905.902.467 3.54 2.002 4.428 1.814.488-.414.484-1.027-.022-1.39-1.092-.782-3.86-2.064-5.051-2.472.751-.409 1.522-.782 2.282-1.175l18.052-9.204c1.01-.51 1.997-1.086 3.013-1.578Z"
    />
    <path
      fill="#0A0A0D"
      d="m32.239 5.755.046.001c.12.165.028 5.039.028 5.723l.007 28.917c-1.017.41-1.991.948-2.973 1.438l-14.436 7.355c-2.303 1.169-4.825 2.43-7.056 3.7-.16-.513-.075-4.398-.075-5.248V28.46c0-.897.042-1.83-.041-2.72 2.708-1.307 5.48-2.522 8.205-3.834.51-.245 1.434-.531 1.822-.912.24-2.487.106-6.615.013-9.137.97-.44 1.956-.844 2.934-1.264l6.597-2.777c1.649-.683 3.247-1.453 4.929-2.061Z"
    />
  </svg>
)
export default SvgComponent
