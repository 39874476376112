// src/components/CardSlider.jsx
import React from "react";
import Slider from "react-slick";
import Card from "./Card";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-prev-arrow`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <KeyboardArrowLeftIcon className="arrowSlider" />
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-next-arrow`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <KeyboardArrowRightIcon className="arrowSlider" />
    </div>
  );
};

const CardSlider = ({ cards }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Slider {...settings}>
      {cards.map((card, index) => (
        <Card
          key={index}
          {...card}
          className={index === 1 ? "card cardSelect" : "card"} // Aplicar estilo personalizado a la tarjeta 2
          buttonClass={index === 1 ? "btnBgBlack" : ""} // Aplicar estilo personalizado al botón de la tarjeta 2
        />
      ))}
    </Slider>
  );
};

export default CardSlider;
