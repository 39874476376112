import React from "react";
import LogoPart from "../assets/images/Icon/LogoPart";
import videoDesign from '../assets/images/video/video-developer2.mp4';
import Iphone from '../assets/images/iphone.png';
import Admin from '../assets/images/admin-d.png';
import Button from './Button'
import WorkIcon from '@mui/icons-material/Work';
const datos = {
    subtitulo: "Autoadministrables",
    titulo: "Obten control total en tus manos",
    texto: " Ofrecemos sitios web autoadministrables que te permiten gestionar tu contenido de manera fácil y rápida. Sin depender de terceros, puedes actualizar textos, imágenes y productos desde un panel de control intuitivo. ¡Mantén tu sitio siempre actualizado y relevante con solo unos clics!",
    video: "https://www.youtube.com/embed/kP6_nlTdPgI?playlist=kP6_nlTdPgI&loop=1&autoplay=1&controls=0&modestbranding=1&showinfo=0&rel=0"
}

function Administrable() {
    return ( 
        <section className="diseño administrable">
            <div className="bgSection">
                <div className="container">
                    <div className="grid-2">
                    <div className="drh">
                            <div className="contetVideoDiseño">
                                <img src={Iphone} alt=''/>
                                <img className="imgAdmin" src={Admin} alt=''/>
                            <video  muted autoPlay loop  className="videoDesign" >
                                <source src={videoDesign} type="video/mp4" />
                            </video>

                            </div>
                        </div>
                        <div>
                            <div className="titleSection titleSectionStart">
                                <h6>
                                    <LogoPart />
                                    { datos.subtitulo }
                                </h6>
                                <h2>{datos.titulo}</h2>
                                <p>
                                {datos.texto}
                                </p>
                                <a href='#planes'>
                                    <Button href="#portafolio">
                                        Ver Planes 
                                    </Button>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>  
        </section>
     );
}

export default Administrable;