// src/components/Card.jsx
import React from "react";
import Accordion from "../Accordion";
import Button from "../Button";
import EastIcon from "@mui/icons-material/East";
import { WhatsApp } from "@mui/icons-material";

const Card = ({ title, description, accordionItems, priceUSD, priceCOP, className, buttonClass }) => {
  const numero = "+573214381060";
  const message = `Hola, me interesa saber más información sobre este servicio: ${title}`;
  const whatsappLink = `https://api.whatsapp.com/send?phone=${numero}&text=${encodeURIComponent(message)}`;

  return (
    <div className={className}>
      <div className="cardTitle">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
      <div className="cardBody">
        <Accordion items={accordionItems} />
        {/* <h6 className="precio">
          {priceUSD} <span>USD</span>
        </h6>
        <h6 className="precio">
          {priceCOP} <span>COP</span>
        </h6> */}
        <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
          <Button className={buttonClass}>
            Más información <WhatsApp />
          </Button>
        </a>
      </div>
    </div>
  );
};

export default Card;
