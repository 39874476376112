import React from "react";
import LogoPart from "../../assets/images/Icon/LogoPart";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CardSlider from "./CardSlider";
import Info from "../../assets/images/Icon/Info";

const accordionItems = [
  {
    title: "Incluye",
    content: [
      <>
        <div className="info">
          Hosting <Info />
          <p className="infoActive">
            Es un servicio que alquila espacio en servidores para almacenar archivos y datos de un sitio web, permitiendo su acceso en Internet.
          </p>
        </div>
      </>,
      <>
        <div className="info">
          Dominio <Info />
          <p className="infoActive">
            Es un nombre único a nivel mundial para identificarse en Internet, por ejemplo, www.ejemplo.com.
          </p>
        </div>
      </>,
      <>
        <div className="info">
          SSL <Info />
          <p className="infoActive">
            Es un protocolo de seguridad que crea conexiones cifradas entre servidores web y navegadores, protegiendo la privacidad y seguridad de la información transferida.
          </p>
        </div>
      </>,
    ],
  },
  {
    title: "Páginas",
    content: ["Inicio", "Nosotros", "Servicios", "Portafolio", "Contacto"],
  },
  {
    title: "Secciones administrables",
    content: ["Slider", "Servicios", "Portafolio", "Datos de contacto"],
  },
];

const paginasWeb = [
  {
    title: "Incluye",
    content: [
      <>
        <div className="info">
          Hosting <Info />
          <p className="infoActive">
            Incluye un servicio de almacenamiento que alquila un espacio en un servidor para almacenar los archivos y datos del sitio web que desarrollaremos para ti, permitiendo su acceso en Internet.
          </p>
        </div>
      </>,
      <>
        <div className="info">
          Dominio <Info />
          <p className="infoActive">
            Incluye un nombre único a nivel mundial para identificarse en Internet, por ejemplo, www.ejemplo.com.
          </p>
        </div>
      </>,
      <>
        <div className="info">
          SSL <Info />
          <p className="infoActive">
            Incluye un protocolo de seguridad que crea conexiones cifradas entre servidores web y navegadores, protegiendo la privacidad y seguridad de la información transferida.
          </p>
        </div>
      </>,
      <>
        <div className="info">
        3 correos corporativos <Info />
          <p className="infoActive">
            Incluye tres correos personalizado profesional que se adapta con el dominio de tu sitio web por ejemplo: contacto@tudominio.com
          </p>
        </div>
      </>,
    ],
  },
  {
    title: "Diseño",
    content: [
      <>
      <div className="info">
      Diseño a la Medida UX/UI: <Info />
        <p className="infoActive">
        Creamos una experiencia única y memorable para tus usuarios, cuidando cada detalle desde la concepción hasta la implementación.
        </p>
      </div>
    </>,
    <>
      <div className="info">
      Diseño en Alta Fidelidad <Info />
        <p className="infoActive">
        Eleva la presentación de tu proyecto con nuestro Diseño en Alta Fidelidad. Cada detalle cobra vida con claridad y precisión, ofreciendo una representación visual excepcional.
        </p>
      </div>
    </>,
    <>
      <div className="info">
      Diseño Personalizado desde Cero<Info />
        <p className="infoActive">
        Desarrollamos tu visión desde cero con nuestro Diseño Personalizado. Cada elemento es concebido exclusivamente para ti, asegurando que tu proyecto destaque en originalidad y refleje la esencia de tu marca.
        </p>
      </div>
    </>,
    ],
  },
  {
    title: "Páginas",
    content: ["Inicio","Servicios o productos","vista previa de cada servicio o producto","Nosotros","Portafolio","Contacto"],
  },
  {
    title: "Secciones de inicio",
    content: ["2 Banner principales corredizos (slider)", "Características", "Sobre la empresa","Servicios o productos","Banner de contacto","Portafolio","Preguntas frecuentes","Testimonios"],
  },
  {
    title: "Secciones administrables",
    content: ["Banner corredizo (slider)", "Servicios o productos","Portafolio", "Datos de contacto"],
  },
  {
    title: "Funciones",
    content: ["Redes sociales", "Slider", "Google maps","Botón flotante de whatsApp","Panel autoadministrable"],
  },
];
const landingPage = [
  {
    title: "Incluye",
    content: [
      <>
        <div className="info">
          Hosting <Info />
          <p className="infoActive">
            Incluye un servicio de almacenamiento que alquila un espacio en un servidor para almacenar los archivos y datos del sitio web que desarrollaremos para ti, permitiendo su acceso en Internet.
          </p>
        </div>
      </>,
      <>
        <div className="info">
          Dominio <Info />
          <p className="infoActive">
            Incluye un nombre único a nivel mundial para identificarse en Internet, por ejemplo, www.ejemplo.com.
          </p>
        </div>
      </>,
      <>
        <div className="info">
          SSL <Info />
          <p className="infoActive">
            Incluye un protocolo de seguridad que crea conexiones cifradas entre servidores web y navegadores, protegiendo la privacidad y seguridad de la información transferida.
          </p>
        </div>
      </>,
      <>
        <div className="info">
        1 correos corporativos <Info />
          <p className="infoActive">
            Incluye un correo personalizado profesional que se adapta con el dominio de tu sitio web por ejemplo: contacto@tudominio.com
          </p>
        </div>
      </>,
    ],
  },
  {
    title: "Diseño",
    content: [
      <>
      <div className="info">
      Diseño a la Medida UX/UI: <Info />
        <p className="infoActive">
        Creamos una experiencia única y memorable para tus usuarios, cuidando cada detalle desde la concepción hasta la implementación.
        </p>
      </div>
    </>,
    <>
      <div className="info">
      Diseño en Alta Fidelidad <Info />
        <p className="infoActive">
        Eleva la presentación de tu proyecto con nuestro Diseño en Alta Fidelidad. Cada detalle cobra vida con claridad y precisión, ofreciendo una representación visual excepcional.
        </p>
      </div>
    </>,
    <>
      <div className="info">
      Diseño Personalizado desde Cero<Info />
        <p className="infoActive">
        Desarrollamos tu visión desde cero con nuestro Diseño Personalizado. Cada elemento es concebido exclusivamente para ti, asegurando que tu proyecto destaque en originalidad y refleje la esencia de tu marca.
        </p>
      </div>
    </>,
    ],
  },
  {
    title: "Páginas",
    content: ["Inicio"],
  },
  {
    title: "Secciones de inicio",
    content: ["2 Banner principales corredizos (slider)", "Características", "Sobre la empresa","Servicios o productos","Banner de contacto","Preguntas frecuentes","Testimonios","Formulario de contacto.","Google maps"],
  },
  {
    title: "Secciones administrables",
    content: ["Banner corredizo (slider)", "Servicios o productos", "Datos de contacto"],
  },
  {
    title: "Funciones",
    content: ["Redes sociales", "Slider", "Google maps","Botón flotante de whatsApp","Panel autoadministrable"],
  },
];
const tiendaOnlineWhatsapp = [
  {
    title: "Incluye",
    content: [
      <>
        <div className="info">
          Hosting <Info />
          <p className="infoActive">
            Incluye un servicio de almacenamiento que alquila un espacio en un servidor para almacenar los archivos y datos del sitio web que desarrollaremos para ti, permitiendo su acceso en Internet.
          </p>
        </div>
      </>,
      <>
        <div className="info">
          Dominio <Info />
          <p className="infoActive">
            Incluye un nombre único a nivel mundial para identificarse en Internet, por ejemplo, www.ejemplo.com.
          </p>
        </div>
      </>,
      <>
        <div className="info">
          SSL <Info />
          <p className="infoActive">
            Incluye un protocolo de seguridad que crea conexiones cifradas entre servidores web y navegadores, protegiendo la privacidad y seguridad de la información transferida.
          </p>
        </div>
      </>,
      <>
        <div className="info">
        3 correos corporativos <Info />
          <p className="infoActive">
            Incluye tres correos personalizado profesional que se adapta con el dominio de tu sitio web por ejemplo: contacto@tudominio.com
          </p>
        </div>
      </>,
    ],
  },
  {
    title: "Diseño",
    content: [
      <>
      <div className="info">
      Diseño a la Medida UX/UI: <Info />
        <p className="infoActive">
        Creamos una experiencia única y memorable para tus usuarios, cuidando cada detalle desde la concepción hasta la implementación.
        </p>
      </div>
    </>,
    <>
      <div className="info">
      Diseño en Alta Fidelidad <Info />
        <p className="infoActive">
        Eleva la presentación de tu proyecto con nuestro Diseño en Alta Fidelidad. Cada detalle cobra vida con claridad y precisión, ofreciendo una representación visual excepcional.
        </p>
      </div>
    </>,
    <>
      <div className="info">
      Diseño Personalizado desde Cero<Info />
        <p className="infoActive">
        Desarrollamos tu visión desde cero con nuestro Diseño Personalizado. Cada elemento es concebido exclusivamente para ti, asegurando que tu proyecto destaque en originalidad y refleje la esencia de tu marca.
        </p>
      </div>
    </>,
    ],
  },
  {
    title: "Páginas",
    content: ["Inicio","Tienda","Vista previa de productos","Carrito","Contacto"],
  },
  {
    title: "Secciones de inicio",
    content: ["2 Banner principales corredizos (slider)", "Características de la tienda", "Categorías de productos","Productos en oferta","Ultimos productos agregados", "Productos destacados","Banner de contacto"],
  },
  {
    title: "Secciones administrables",
    content: ["Banner corredizo (slider)", "Servicios o productos","Sección de ofertas", "Sección de destacados","Datos de contacto"],
  },
  {
    title: "Funciones",
    content: ["Redes sociales", "Slider", "Google maps","Botón flotante de whatsApp","Panel autoadministrable","Stock de productos"],
  },
];
const tiendaOnlineProfesional = [
  {
    title: "Incluye",
    content: [
      <>
        <div className="info">
          Hosting <Info />
          <p className="infoActive">
            Incluye un servicio de almacenamiento que alquila un espacio en un servidor para almacenar los archivos y datos del sitio web que desarrollaremos para ti, permitiendo su acceso en Internet.
          </p>
        </div>
      </>,
      <>
        <div className="info">
          Dominio <Info />
          <p className="infoActive">
            Incluye un nombre único a nivel mundial para identificarse en Internet, por ejemplo, www.ejemplo.com.
          </p>
        </div>
      </>,
      <>
        <div className="info">
          SSL <Info />
          <p className="infoActive">
            Incluye un protocolo de seguridad que crea conexiones cifradas entre servidores web y navegadores, protegiendo la privacidad y seguridad de la información transferida.
          </p>
        </div>
      </>,
      <>
        <div className="info">
        5 correos corporativos <Info />
          <p className="infoActive">
            Incluye cinco correos personalizado profesional que se adapta con el dominio de tu sitio web por ejemplo: contacto@tudominio.com
          </p>
        </div>
      </>,
    ],
  },
  {
    title: "Diseño",
    content: [
      <>
      <div className="info">
      Diseño a la Medida UX/UI: <Info />
        <p className="infoActive">
        Creamos una experiencia única y memorable para tus usuarios, cuidando cada detalle desde la concepción hasta la implementación.
        </p>
      </div>
    </>,
    <>
      <div className="info">
      Diseño en Alta Fidelidad <Info />
        <p className="infoActive">
        Eleva la presentación de tu proyecto con nuestro Diseño en Alta Fidelidad. Cada detalle cobra vida con claridad y precisión, ofreciendo una representación visual excepcional.
        </p>
      </div>
    </>,
    <>
      <div className="info">
      Diseño Personalizado desde Cero<Info />
        <p className="infoActive">
        Desarrollamos tu visión desde cero con nuestro Diseño Personalizado. Cada elemento es concebido exclusivamente para ti, asegurando que tu proyecto destaque en originalidad y refleje la esencia de tu marca.
        </p>
      </div>
    </>,
    ],
  },
  {
    title: "Páginas",
    content: ["Inicio","Tienda","Vista previa de productos","Carrito","Contacto","nosotros","Ingreso","Registro","Recuperar contraseña","Favoritos","Finalizar compra","Panel de clientes.","Historial de pedidos","Perfil"],
  },
  {
    title: "Secciones de inicio",
    content: ["3 Banner principales corredizos (slider)", "Características de la tienda", "Categorías de productos","Productos en oferta","Ultimos productos agregados", "Productos destacados","Banner de contacto"],
  },
  {
    title: "Secciones administrables",
    content: ["Banner corredizo (slider)", "Servicios o productos","Sección de ofertas", "Sección de destacados","Datos de contacto"],
  },
  {
    title: "Funciones",
    content: ["Redes sociales", "Slider", "Google maps","Botón flotante de whatsApp","Panel autoadministrable","Buscador de productos dinámico","Carrito de compras","Favoritos","Filtros de tienda, precio, categorías, color, talla","Gestión de inventario","Analíticas de la tienda","Formulario de suscripción con auto responder","Pasarela de pagos","Panel para clientes","Sistema de ingreso y registro","Historial de compras de clientes", ],
  },
];


const cards = [
  {
    title: "Landing page",
    description: "Diseño y desarrollo de una página de aterrizaje optimizada para captar la atención de tus clientes desde el primer vistazo.",
    accordionItems: landingPage, // Pasando `accordionItems`
    priceUSD: "$150",
    priceCOP: "$550.000",
  },
  {
    title: "Página Web",
    description: "Creación y desarrollo de sitios web personalizados, adaptados a tus necesidades y objetivos empresariales.",
    accordionItems: paginasWeb, // Pasando `paginasWeb`
    priceUSD: "$220",
    priceCOP: "$880.000",
  },
  {
    title: "Tienda online (Tipo WhatsApp)",
    description: "Configuración de una tienda online con funcionalidades similares a WhatsApp para una experiencia de compra sencilla y directa.",
    accordionItems: tiendaOnlineWhatsapp, // Pasando `accordionItems`
    priceUSD: "$290",
    priceCOP: "$950.000",
  },
  {
    title: "Tienda online profesional",
    description: "Desarrollo integral de tiendas online con carrito de compras, gestión de productos, y métodos de pago seguros.",
    accordionItems: tiendaOnlineProfesional, // Pasando `accordionItems`
    priceUSD: "$420",
    priceCOP: "$1.500.000",
  },
];




function Planes() {

  return (
    <section id="planes" className="planes">
      <div className="container">
        <div className="titleSection">
          <h6>
            <LogoPart />
            Nuestros planes
          </h6>
          <h2>Transformamos tus ideas en soluciones digitales</h2>
          <p>
            Desarrollo web, tiendas online, sistemas de gestión, POS y apps
            móviles. Soluciones personalizadas para impulsar tu negocio.
          </p>
        </div>
        <CardSlider cards={cards} />
      </div>
    </section>
  );
}

export default Planes;
